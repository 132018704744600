@value max-small, max-medium, max-large, max-xlarge, min-large from "@/styles/variables.module.css";

.listingNav {
  display: flex;
  gap: 1.2rem;
  transition: color 0.3s ease;
  width: 100%;
  overflow-x: auto;

  @media min-large {
    margin: 1.8rem 0;
  }
}

.listingNav::-webkit-scrollbar {
  background: transparent;
  width: 0px;
}

.listingNavItem {
  cursor: pointer;
  font-size: 18px;
  white-space: nowrap;
}

.listingNavItem:hover {
  @media min-large {
    opacity: 0.8;
  }
}
