.wrapper {
  display: flex;
  gap: 24px;
  margin: 12px 0;
  flex-wrap: wrap;
}

.linkContainer {
  display: flex;
  row-gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
